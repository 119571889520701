import React, { useState } from "react";
import { Container } from "react-bootstrap";

import { IImage, IParagraph } from "../.,/../../../models/cmsModels";
import SEO from "../../../components/seo";
import ImageGallery from "../ImageGallery/image-gallery";

interface ISectionParagraphs {
    title: string;
    description: string;
    paragraphs: any[];
}

const SectionParagraphs: React.FC<ISectionParagraphs> = (props) => {
    const [galleryImages, setGalleryImages] = useState([] as IImage[]);
    const [imageIndex, setImageIndex] = useState(null as number);
    const [isImageGalleryOpen, setImageGalleryOpen] = useState(false);

    const { title, description, paragraphs } = props;

    const paragraphStyle: any = (paragraph: IParagraph) => {
        let direction = '';
    
        switch (paragraph.imagesOrientation) {
          case 'Left': direction = 'row-reverse'; break;
          case 'Right': direction = 'row'; break;
          case 'Top': direction = 'column-reverse'; break;
          case 'Bottom': direction = 'column'; break;
          default: direction = 'column'; break;
        }
    
        return {
          display: 'flex',
          flexDirection: direction
        }
    }

    const imagesStyle: any = (paragraph: IParagraph) => {
        let direction = '';
    
        switch (paragraph.imagesOrientation) {
          case 'Left':
          case 'Right': direction = 'column'; break;
          case 'Top': 
          case 'Bottom': direction = 'row'; break;
          default: direction = 'row'; break;
        }
    
        return {
          display: 'flex',
          flexDirection: direction
        }
    };

    const openImageGallery = (images: IImage[], index: number) => {
        setGalleryImages(images);
        setImageIndex(index);
        setImageGalleryOpen(true);
    };

    const closeImageGallery = () => {
        setGalleryImages([]);
        setImageIndex(null);
        setImageGalleryOpen(false);
    };
    
    return (
        <>
            <SEO title={title} description={description}></SEO>
            {
                !isImageGalleryOpen ? <></> :
                <ImageGallery images={galleryImages} defaultIndex={imageIndex} onClose={() => closeImageGallery()} />
            }
            <h3>{title}</h3>
            <br/>
            {
                !paragraphs ? <></> :
                paragraphs.map((para: IParagraph, p: number) => (
                <div style={paragraphStyle(para)}>
                    <div key={p} dangerouslySetInnerHTML={{__html: para.contents}}></div>
                    <div style={imagesStyle(para)}>
                        {
                        para.images.map((image, i: number) => (
                        <>
                            <img key={i} width={image.width/2} src={image.url} onClick={() => openImageGallery(para.images, i)} title="Click to enlarge"/>
                        </>
                        ))
                        } 
                    </div>
                </div>
                ))
            }
            <br></br>
        </>
    )
};

export default SectionParagraphs;
